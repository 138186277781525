import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Error from "./pages/Error";
import Privacy from "./pages/Privacy";
import SharedLayout from "./components/SharedLayout";
import ScrollToTop from "./components/ScrollToTop";
import Terms from "./pages/Terms";


function App() {

	return (
		<div className="App">
			<BrowserRouter>
				<ScrollToTop />
				<Routes>
					<Route path="/" element={<SharedLayout />}>
						<Route index="/" element={<Home />} />
						<Route path="about" element={<About />} />
						<Route path="contact" element={<Contact />} />
						<Route path="privacy" element={<Privacy />} />
						<Route path="terms" element={<Terms />} />
						<Route exact path="*" element={<Error />} />
					</Route>
				</Routes>
			</BrowserRouter>
		</div>
	);
}

export default App;
