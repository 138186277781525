import React from "react";
import { Link } from "react-router-dom";
import "../styles/Error.css";
import robot from '../images/robot.png';
const Error = () => {
	return (
		<div className="error">
			<img src={robot} alt={robot} className="robot"/>
			<h1>404 Error!</h1>
			<p>The page you are looking for isn't available on this website or doesn't exist</p>

			<Link to="/">
				<button type="button" className="btn">
					go back to home
				</button>
			</Link>
		</div>
	);
};
export default Error;
