import React from "react";
import "../styles/Header.css";
import boy from "../images/header_boy.png";
import girl from "../images/header_girl.png";
import magnet from "../images/magnet.svg";
import brain from "../images/brain.svg";
import triangle from "../images/triangle.svg";
import microscope from "../images/microscope.svg";
import molecules from "../images/molecules.svg";
import planet from "../images/planet.svg";
import timer from "../images/timer.svg";
import { BsArrowRightCircleFill } from "react-icons/bs";


const Header = () => {
	return (
		<>
			<div className="header_container">
				<div className="header_boy">
					<img src={boy} alt="boy" />
				</div>
				<div className="text_items">
					<div className="header_intro">
						<h1>A more interesting future for your kids</h1>
						<p>
							We employ interactive and engaging educational activities to introduce and demonstrate the principles and
							applications of Science, Technology, Engineering, Arts and Math (STEAM) to young learners.
						</p>
					</div>
					<div className="header_numbers">
						<div className="numbers_container">
							<div className="number">
								<h4>500+ </h4>
								<p>Total students currently enrolled in different fields of study</p>
							</div>
							<div className="number">
								<h4>12+ </h4>
								<p>Available programmes and areas of interest and increasing</p>
							</div>
							<div className="number">
								<h4>08 </h4>
								<p>Partners across varied fields of engineering and childhood development</p>
							</div>
						</div>
					</div>
					<button className="button_edit">
						ENROLL YOUR CHILD <BsArrowRightCircleFill className="arrow" />
					</button>
				</div>

				<div className="header_girl">
					<img src={girl} alt="girl" />
				</div>
				<div className="magnet">
					<img src={magnet} alt="magnet" />
				</div>
				<div className="brain">
					<img src={brain} alt="brain" />
				</div>
				<div className="triangle">
					<img src={triangle} alt="triangle" />
				</div>
				<div className="microscope">
					<img src={microscope} alt="microscope" width={200} height={520} />
				</div>
				<div className="molecules">
					<img src={molecules} alt="molecules" />
				</div>
				<div className="planet">
					<img src={planet} alt="planet" />
				</div>
				<div className="timer">
					<img src={timer} alt="timer" />
				</div>
			</div>

		</>
	);
};

export default Header;
