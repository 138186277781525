import React from "react";
import ieee from "../images/IEEE.svg";
import a4ed from "../images/A4ED.svg";
import codeClub from "../images/codeClub.svg";
import unicaf from "../images/UNICAF.svg";
import smartGirls from "../images/smartGirls.svg";
import unesco from "../images/unesco.svg";
import covid from "../images/covid.svg";
import film from "../images/film.svg";

// css
import "../styles/Partners.css";
const Partners = () => {
	return (
		<section className="partner_section container">
			<div className="partner_items">
				<div className="partners">
					<h1>Our partners</h1>
					<p>We proud to work with a number of amazing individuals and organisations</p>
				</div>
				<div className="logos">
					<div className="logo_item">
						<img src={ieee} width={140} height={70} alt="logo" className="logo" />
					</div>
					<div className="logo_item">
						<img src={a4ed} width={160} height={90} alt="logo" className="logo" />
					</div>
					<div className="logo_item">
						<img src={codeClub} width={180} height={90} alt="logo" />
					</div>
					<div className="logo_item">
						<img src={unicaf} width={160} height={80} alt="logo" />
					</div>
					<div className="logo_item">
						<img src={smartGirls} width={160} height={80} alt="logo" />
					</div>
					<div className="logo_item">
						<img src={unesco} width={160} height={80} alt="logo" />
					</div>
					<div className="logo_item">
						<img src={covid} width={160} height={80} alt="covid" />
					</div>
					<div className="logo_item">
						<img src={film} width={160} height={80} alt="film" />
					</div>
				</div>
			</div>
		</section>
	);
};
export default Partners;
