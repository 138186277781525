import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import logo from "../images/logo-01.svg";
import { CgClose, CgMenuRightAlt } from "react-icons/cg";

const Navbar = () => {
	const [showLinks, setShowLinks] = useState(false);
	const [navbar, setNavbar] = useState(false);

	const navBackground = () => {
		if (window.scrollY >= 56) {
			setNavbar(true);
		} else {
			setNavbar(false);
		}
	};
	window.addEventListener("scroll", navBackground);

	return (
		<nav className={navbar ? "nav_container nav_active" : "nav_container"}>
			<div className="container">
				<div className="navbar">
					<div className="nav_menu">
						<Link to="/" className="logo">
							<img src={logo} alt="boltClub" />
						</Link>
						<button className="nav_toggle" onClick={() => setShowLinks(!showLinks)}>
							{showLinks ? <CgClose className="close" /> : <CgMenuRightAlt className="open" />}
						</button>
					</div>
					<div className={!showLinks ? "nav_links" : "nav_links mobile"} onClick={() => setShowLinks(!showLinks)}>
						<NavLink to="/" className="nav_link">
							Home
						</NavLink>

						<NavLink to="/about" className="nav_link">
							About
						</NavLink>

						<NavLink to="/contact" className="nav_link">
							Contact
						</NavLink>
					</div>
				</div>
			</div>
		</nav>
	);
};

export default Navbar;
