import React from "react";
import { Link } from "react-router-dom";
import "../styles/Footer.css";
import whiteLogo from "../images/logo_white-01.svg";
import { FaFacebook, FaInstagram, FaTwitter } from "react-icons/fa";

const Footer = () => {
	return (
		<footer className="footer">
			<div className="footer_items container">
				<div className="footer_content">
					<div className="address">
						<Link to="/">
							<img src={whiteLogo} alt="boltClub" />
						</Link>
						<ul className="address_element">
							<li>Plot 702 Namuli Road, Bukoto</li>
							<li>Phone: +256 393 242948</li>
							<li>WhatsApp: +256 393 242948</li>
						</ul>
					</div>
					<div className="footer_links">
						<div className="link_details">
							<ul className="extra_links">
								{/* <Link to="/login">Login</Link> */}
								<li>
									<Link to="/terms">Terms of Service</Link>
								</li>
								<li>
									<Link to="/privacy">Privacy Policy</Link>
								</li>
							</ul>
						</div>

						<ul className="link_items">
							<li>
								<Link to="/about">About</Link>
							</li>
							<li>
								<Link to="/outreach">Outreach</Link>
							</li>
							<li>
								<Link to="/bolt">Bolt Club</Link>
							</li>
							<li>
								<Link to="/Shop">Shop</Link>
							</li>
							<li>
								<Link to="/contact">Contact us</Link>
							</li>
						</ul>

						<ul className="icons">
							<li>
								<Link to="" target="_blank" onClick={() => (window.location = "https://twitter.com/SilverBoltUG")}>
									<FaTwitter className="icon_links" />
								</Link>
							</li>
							<li>
								<Link to="" target="_blank" onClick={() => (window.location = "https://instagram.com/SilverBoltUG")}>
									<FaInstagram className="icon_links" />
								</Link>
							</li>
							<li>
								<Link
									to="#"
									target="_blank"
									onClick={() => (window.location = "https://www.facebook.com/BoltClubUG/?_rdc=1&_rdr")}
								>
									<FaFacebook className="icon_links" />
								</Link>
							</li>
						</ul>
					</div>
				</div>
				<div className="footer_details">
					<p className="copy">&copy; SILVER BOLT {new Date().getFullYear()}. All rights reserved.</p>
					<p className="designer">
						Design & Development by &nbsp;
						<Link
							to="https://www.linkedin.com/in/gerald-ssali-06497220/"
							onClick={() => (window.location = "https://www.linkedin.com/in/gerald-ssali-06497220/")}
						>
							<span>Gerald Ssali</span>
						</Link>
					</p>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
