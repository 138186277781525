import React from "react";
import "../styles/About.css";
import boy from "../images/african-american-boy-with-friends-grass-exploring-looking-nature-with-magnifying-glass-education-outdoor-concept 2.png";
import girl from "../images/african-american-boy-with-friends-grass-exploring-looking-nature-with-magnifying-glass-education-outdoor-concept 3.png";

import Partners from "../components/Partners";

export default function AboutPage() {
	return (
		<div className="about">
			<div className="header">
				<h1 className="container">ABOUT</h1>
			</div>
			<section className="about_content container ">
				<div className="intro_text">
					<div className="introduction">
						<h1>Our mission is to promote a culture of science awareness and technology utilization</h1>
					</div>

					<div className="intro_preamble">
						<p>
							Silver Bolt is a Ugandan not-for-profit organisation that educates and inspires youth to follow careers in
							Science and Engineering; empowers early career graduates and budding innovators to capably join the
							workforce, and works with professionals to nurture the next generation of engineering and computing
							professionals.
						</p>
						<p>
							Silver Bolt employs interactive and engaging educational activities to introduce and demonstrate the
							principles and applications of Science, Technology, Engineering, Art and Math (STEAM) to young learners,
							provides unique training opportunities for career professionals and budding innovators, and enables
							practicing professionals to upskill and maintain their proficiency in an evolving workplace.​
						</p>
					</div>
				</div>
				<div className="intro_images ">
					<div className="girl">
						<img src={girl} alt="african girl lego" />
					</div>
					<div className="boy">
						<img src={boy} alt="african boy with microscope" />
					</div>
				</div>
			</section>
			<Partners />
		</div>
	);
}
