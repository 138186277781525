import React from "react";
import Header from "../components/Header";
import Partners from "../components/Partners";
import FourItems from "../components/FourItems";
import Explore from "../components/Explore";
import Methods from "../components/Methods";

const Home = () => {
	return (
		<div>
			<Header />
			<FourItems />
			<Methods />
			<Explore />
			<Partners />
		</div>
	);
};

export default Home;
