import React from "react";
import "../styles/Methods.css";
import boltClub from "../images/boy_microscope.png";
import mentorship from "../images/mentorship.png";
import outreach from "../images/outreach.png";
import shop from "../images/shop.png";

const Methods = () => {
	return (
		<section className="methods">
			<div className="yellow_circle"></div>
			<div className="pink_circle"></div>
			<div className="orange_circle"></div>
			<div className="container">
				<div className="method_intro">
					<h3>Multi-method approach</h3>
					<p>Interconnected programmes designed to enhance learning experiences</p>
				</div>
				<div className="methods_container">
					<div className="method bolt_club">
						<div className="image">
							<img src={boltClub} alt="bolt club" />
						</div>

						<h5>Bolt Club</h5>
						<p>
							It provides curriculum enrichment to STEM (Science, Technology, Engineering, Mathematics) education by
							employing age-appropriate hands-on, inquiry-based activities aimed at inspiring learning and promoting
							personal development.
						</p>
					</div>
					<div className="method mentorship">
						<div className="image">
							<img src={mentorship} alt="bolt mentorship" />
						</div>

						<h5>Bolt Mentorship</h5>
						<p>
							Creating a developmental space in which skills are honed and pathways to the science, technology,
							engineering and innovation industry can be discovered. Learning with a purpose.
						</p>
					</div>
					<div className="method outreach">
						<div className="image">
							<img src={outreach} alt="bolt outreach" />
						</div>

						<h5>Bolt Outreach</h5>
						<p>
							Supporting community skilling for development through raising awareness of the role of science,
							technology, engineering and innovation plays. Introducing STEM to underserved learning communities to
							increase chances of uptake and use
						</p>
					</div>
					<div className="method  shop">
						<div className="image">
							<img src={shop} alt="bolt shop" />
						</div>

						<h5>Bolt Shop</h5>
						<p>
							Supporting learning through play by creation of puzzles and creativity in our studio. Offer opportunity to
							students to learn through experiencing design, creation and distribution of products to the desired
							audience.
						</p>
					</div>
				</div>
			</div>
		</section>
	);
};
export default Methods;
