import React, { useRef } from "react";
import { Link } from "react-router-dom";
import "../styles/Contact.css";

// emailjs
import emailjs from "@emailjs/browser";
// import { send } from "emailjs-com";

//toastify
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Contact = () => {
	const form = useRef("");
	// const [person, setPerson] = useState({ name: "", email: "", phone: "", message: "" });
	// const handleChange = (e) => {
	// 	setPerson({ ...person, [e.target.name]: e.target.value });
	// };

	const sendEmail = (e) => {
		e.preventDefault();

		emailjs.sendForm("service_7oc79by", "template_d4h9huh", form.current, "U_mcgBLFXha58p5N-").then(
			(result) => {
				console.log(result);
			},
			(error) => {
				console.log(error.text);
			},
		);
		form.current.reset();
	};

	// notify
	const notify = () => toast.success("Your email was sent successfully");
	return (
		<div className="contact">
			<div className="contact_header">
				<h1 className="container">CONTACTS</h1>
			</div>
			<section className="contact_content container">
				<div className="form_intro">
					<h2>We are always happy to help, 24/7.</h2>
				</div>
				<div className="form_details">
					<div className="contact_form">
						<form onSubmit={sendEmail} ref={form}>
							<ToastContainer />
							<div className="form_group">
								<label htmlFor="name">Name:</label>
								<input type="text" name="user_name" required placeholder="Nathaniel Arthur" />
							</div>
							<div className="form_group">
								<label htmlFor="email">eMail:</label>
								<input type="email" name="user_email" required placeholder="example@email.com" />
							</div>
							<div className="form_group">
								<label htmlFor="phone">Phone Number:</label>
								<input type="phone" name="user_phone" required placeholder="+256 750 XXXXXX" />
							</div>
							<textarea
								name="message"
								placeholder=" write your message"
								id=""
								cols="30"
								rows="6"
								className="form_group"
								required
							></textarea>
							<button onClick={notify} className="send_btn">
								send message
							</button>
						</form>
					</div>

					<div className="contact_details">
						<div className="contact_extra call">
							<h3>Call us</h3>

							<p>Phone: +256 393 242948</p>
							<p>WhatsApp: +256 393 242948</p>
						</div>
						<div className="contact_extra email">
							<h3>Email us</h3>
							<Link to="" onClick={() => (window.location = "mailto:info@silverbolt.org")}>
								<p>info@silverbolt.org</p>
							</Link>
							{/* <Link to="" onClick={() => (window.location = "mailto:classes@silverbolt.org")}>
								<p>classes@silverbolt.co.ug</p>
							</Link> */}
						</div>
						<div className="contact_extra address">
							<h3>Visit us</h3>
							<p>Plot 702 Namuli Road, Bukoto</p>
							<p>Kampala, Uganda</p>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
};
export default Contact;
