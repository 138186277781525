import React from "react";
import "../styles/Explore.css";
import list from "../images/list.svg";
import explore from "../images/explore.svg";
import guidance from "../images/guidance.svg";
import history from "../images/history.svg";

const Explore = () => {
	return (
		<section className="explore">
			<div className="explore_container container">
				<div className="explore_intro">
					<h4>Exploring the world of science</h4>
					<p>
						Education is not about knowing the answer, it is about knowing which questions to ask and how to think
						critically when a problem presents itself.
					</p>
				</div>
				<div className="explore_elements">
					<div className="element">
						<img src={list} alt="list" />
						<p>Learning by doing</p>
					</div>
					<div className="element">
						<img src={history} alt="history" />
						<p>Inspired through history</p>
					</div>
					<div className="element">
						<img src={explore} alt="explore" />
						<p>Exploring through play</p>
					</div>
					<div className="element">
						<img src={guidance} alt="guidance" />
						<p>Relevance through guidance</p>
					</div>
				</div>
			</div>
		</section>
	);
};
export default Explore;
