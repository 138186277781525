import "../styles/FourData.css";
import learning from "../images/learning.svg";
import free from "../images/free.svg";
import shop from "../images/shop_icon.svg";
import community from "../images/community.svg";
import { BsArrowRightCircleFill } from "react-icons/bs";
import { Link } from "react-router-dom";

const FourItems = () => {
	return (
		<div className="four_container container">
			<div className="four_items">
				<div className="item">
					<div className="item_intro">
						<img src={learning} alt="e-learning" />
						<h4>Interdisciplinary Learning</h4>
						<p>Access our creative computing and supplemental STEM classes both online and/or in-person</p>
					</div>
					<Link to="/contact">
						<button className="btn_small">
							enroll now <BsArrowRightCircleFill className="icon" />
						</button>
					</Link>
				</div>
				<div className="item">
					<div className="item_intro">
						<img src={free} alt="free resources" />
						<h4>Free Resources</h4>
						<p>Register with us and learn how to use free out-of-school STEM learning material </p>
					</div>
					<Link to="/contact">
						<button className="btn_small">
							register now <BsArrowRightCircleFill className="icon" />
						</button>
					</Link>
				</div>
				<div className="item">
					<div className="item_intro">
						<img src={shop} alt="shop with us" />
						<h4>Shop with us</h4>
						<p>Now open! Purchase new and customised accessories in our Bolt Shop </p>
					</div>
					<Link to="/contact">
						<button className="btn_small">
							shop now <BsArrowRightCircleFill className="icon" />
						</button>
					</Link>
				</div>
				<div className="item">
					<div className="item_intro">
						<img src={community} alt="community" />
						<h4>Community Projects</h4>
						<p>Creating an all inclusive STEM learning environment in support of less privileged communities </p>
					</div>
					<Link to="/contact">
						<button className="btn_small">
							get started <BsArrowRightCircleFill className="icon" />
						</button>
					</Link>
				</div>
			</div>
		</div>
	);
};

export default FourItems;
